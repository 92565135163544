<template>
	<b-modal ref="modal-view-site-image" size="lg" id="modal-view-site-image" title="Site Image Preview">

		<b-card-img v-if="!$_.isEmpty(siteImagePath)" :src="siteImagePath"></b-card-img>
		
		<h4 v-if="$_.isEmpty(siteImagePath)">No site image</h4>

		<template #modal-footer>
			<div class="w-100">
				<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary" class="float-right" @click="hideModal">
					Close
				</b-button>
			</div>
		</template>

	</b-modal>
</template>

<script>
	import {
		BModal, VBModal, BButton, BCardImg
	} from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'
	
	export default {
		components: {
			BModal, VBModal, BButton, BCardImg
		},
		directives: {
			Ripple,
		},
		model: {},
		props: {
			siteObject_prop: {}
		},
		watch: {
			siteObject_prop(newval) {
				return newval;
			}
		},
		computed: {
			siteImagePath() {
				if (_.isEmpty(this.siteObject_prop)) {
					return '';
				}
				return this.$root.getUploadFilePath(`website/${this.siteObject_prop.siteImage}?${Date.now()}`);
			}
		},
		created() {
		},
		data() {
			return {
			}
		},
		methods: {
			hideModal() {
				this.$refs['modal-view-site-image'].hide()
			},
		},
	}
</script>