<template>
	<b-col lg="12">
		<b-card no-body>
			<b-card-header>
				<h4 class="mb-0">
					Notes
				</h4>
			</b-card-header>

			<b-row class="mx-0">
				<b-col cols="12" class="border-top d-flex align-items-between flex-column py-1">
					<b-card>
						<b-card-text>
							<strong>How to "Generate - Site Image" works:</strong> Select site first and click on "Generate image" button or simply drag file from local computer. Click on Preview Image button to see the preview of the screenshot.
						</b-card-text>
						<b-card-text class="text-danger">
							<strong>Note:</strong> If you face cache issue due to wp-engine server then you will have to drop the image.
						</b-card-text>
					</b-card>
				</b-col>
			</b-row>
		</b-card>
	</b-col>
</template>

<script>
	import {
		BCard, BCardHeader, BRow, BCol, BCardText,
	} from 'bootstrap-vue'

	export default {
		components: {
			BCard, BCardHeader, BRow, BCol, BCardText,
		},
	}
</script>