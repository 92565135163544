<template>
	<b-row class="match-height">
		<b-col lg="12">
			<b-card no-body class="card-revenue-budget">
				<b-row class="mx-0">
					<b-col md="12" class="revenue-report-wrapper">
						<div class="d-sm-flex justify-content-between align-items-center mb-3">
							<h4 class="card-title mb-50 mb-sm-0">Generate - <strong>Site Image</strong></h4>
							<div class="d-flex align-items-center" v-if="!$_.isEmpty(selectedSite)">
								<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block v-b-modal.modal-view-site-image>
									View Site Image
								</b-button>
							</div>
						</div>
						<b-overlay :show="isFetchingRecordsFromServer" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">
							<b-row>
								<b-col md="6" class="border-right">
									<b-form @submit.prevent>
										<b-form-group label="Select Site" label-for="mc-first-name">
											<Select2 placeholder="Select Site" id="siteDropdown" v-model="filterSite" :options="siteDropDownOptions" @change="selectSiteChangeEvent($event)" />
										</b-form-group>
										<b-form-group label=" " label-for="mc-last-name" v-if="!$_.isEmpty(selectedSite)">
											<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1" @click="generateSiteImage">
												Generate Image
											</b-button>
										</b-form-group>
									</b-form>
								</b-col>
								<b-col md="6" class="border-left" v-if="!$_.isEmpty(selectedSite)">
									<b-form-group label="Drop File And Upload" label-for="mc-last-name">
										<vue-dropzone class="rounded bg-transparent border border-secondary" ref="myVueDropzone" id="site_image_dropzone" :options="dropzoneOptions" @vdropzone-sending="sendingAdditionalParamsDropzoneEvent" @vdropzone-success="fileUploadedServerResponseDropzoneEvent"></vue-dropzone>
									</b-form-group>
								</b-col>
							</b-row>
						</b-overlay>
					</b-col>
				</b-row>
			</b-card>
		</b-col>

		<image-generate-note />

		<view-site-image :siteObject_prop="selectedSite" /> <!-- FOR ADD NEW USER TO SITE -->

	</b-row>
</template>

<script>
	import { BCard, BCardHeader, BRow, BCol, BCardText, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BOverlay } from 'bootstrap-vue'
	import ImageGenerateNote from './ImageGenerateNote.vue'
	import ViewSiteImage from './ViewSiteImage.vue'
	import Ripple from 'vue-ripple-directive'
	import vue2Dropzone from 'vue2-dropzone'
	import 'vue2-dropzone/dist/vue2Dropzone.min.css'
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

	export default {
		components: {
			BCard, BCardHeader, BRow, BCol, BCardText, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BOverlay,
			ImageGenerateNote,
			vueDropzone: vue2Dropzone,
			ViewSiteImage,
		},
		directives: {
			Ripple,
		},
		data() {
			return {
				isFetchingRecordsFromServer: false,
				filterSite: null,
				siteDropDownOptions: [],
				selectedSite: {},
				dropzoneOptions: {
					url: `${this.$root.appBaseURL}/api/take-screenshot/upload-site-image-with-dropzone`,
					thumbnailWidth: 150,
					maxFiles: 1,
					maxFilesize: 5, /* 5 MB */
					acceptedFiles: ".jpeg,.jpg,.png",
					headers: { 
						"is-dropzone-file": "true",
						'app-front-request': 1,
                        'app-front-auth-id': this.$root.authUserObject?.id
					}
				},
				filterSite: null
			}
		},
		created() {
			this.fetchUserSites();
		},
		methods: {
			fetchUserSites() {
				this.$globalAPI.get('site/list', {
					status: 1,
					user_id: this.$root.authUserObject?.id
				}).then((response) => {
					response = response.data;
					if (response.status) {
						for (const site of _.sortBy(response.sites, "siteURL")) {
							this.siteDropDownOptions.push({
								'id': site.siteId,
								'text': site.siteURL,
								'siteImage': site.siteImage,
								'status': site.status,
								'siteURL': site.siteURL,
							});
						}
					}
				});
			},
			selectSiteChangeEvent(siteId) {

				/* FOR GET FIRST SITE ONLY : START */
				let firstSite = _.first(_.filter(this.siteDropDownOptions, function (obj) {
					return obj.id == siteId;
				}));
				/* FOR GET FIRST SITE ONLY : END */

				this.selectedSite = firstSite;
			},
			generateSiteImage() {
				this.isFetchingRecordsFromServer = true;
				if (this.selectedSite.status == 0) {
					this.processForGenerateSiteImage();
				} else {
					/*
					 * DE-ACTIVE SITE FOR REMOVE ALL CAPTURE FROM SITE BEFORE GENERATE SITE IMAGE : START
					 * THIS PROCESS DOING FOR CACHE ISSUE WHEN GENERATING IMAGE
					 */
					this.$takeScreenshotAPI.post('deactive-site', {
						'auth_id': this.$root.authUserObject.id ?? '',
						'site_id': this.selectedSite.id ?? '',
						'site_image': this.selectedSite.siteImage ?? '',
						'site_status': this.selectedSite.status ?? '',
					}).then((response) => {
						response = response.data;
						if (response.status) {
							this.processForGenerateSiteImage();
						} else {
							this.isFetchingRecordsFromServer = false;
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Notification',
									icon: 'BellIcon',
									variant: 'danger',
									text: response.message,
								},
							});
						}
					});
					/*
					 * DE-ACTIVE SITE FOR REMOVE ALL CAPTURE FROM SITE BEFORE GENERATE SITE IMAGE : END
					 */
				}
			},
			processForGenerateSiteImage() {
				this.$takeScreenshotAPI.post('generate-site-image', {
					'auth_id': this.$root.authUserObject.id ?? '',
					'site_id': this.selectedSite.id ?? '',
					'site_image': this.selectedSite.siteImage ?? '',
					'site_status': this.selectedSite.status ?? '',
					'site_url': this.selectedSite.siteURL ?? '',
				}).then((response) => {
					response = response.data;
					if (response.status) {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'success',
								text: response.message,
							},
						});
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
					this.isFetchingRecordsFromServer = false;
				});
			},
			sendingAdditionalParamsDropzoneEvent(file, xhr, formData) {
				formData.append('auth_id', this.$root.authUserObject.id);
				formData.append('site_id', this.selectedSite.id);
				formData.append('site_image', this.selectedSite.siteImage);
				this.isFetchingRecordsFromServer = true;
			},
			fileUploadedServerResponseDropzoneEvent(file, response) {
				response = JSON.parse(response);
				this.isFetchingRecordsFromServer = false;
				if (response.status) {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Notification',
							icon: 'BellIcon',
							variant: 'success',
							text: response.message,
						},
					});
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Notification',
							icon: 'BellIcon',
							variant: 'danger',
							text: response.message,
						},
					});
				}
				this.$refs.myVueDropzone.removeAllFiles(true);
			}
		},
	}
</script>